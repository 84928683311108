@import url("https://use.typekit.net/tmf1hpu.css");

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fffced;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, p22-mackinac-pro, 'Garamond', 'Palatino'
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: p22-mackinac-pro, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  margin: 0;
}

h2 {
  font-family: p22-mackinac-pro, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 4vw;
  margin: 0;
}

h3 {
  font-family: p22-mackinac-pro, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  margin: 0;
}

code {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration-line: underline !important; 
  text-decoration-color: #ff9901 !important;
  /* text-decoration-style: dashed !important; */
}

a:hover {
  text-decoration: none !important;
}

hr.dashed {
  border-top: 1px dashed #ff9901;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* br {
  margin: -5px;
} */

/* a:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .6s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
} */ 

/* 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* 
@import url("https://use.typekit.net/tmf1hpu.css");
@import url("https://use.typekit.net/eiu4gmp.css");

h1 {
  font-family: sofia-pro, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    serif;
}

body {
  margin: 0;
  font-family: p22-mackinac-pro, 'Big Caslon', 'Garamond', 'Georgia', 'Palatino'
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 */

