.about-container {
  position: absolute;
  left: 10%;
  top: 20%;
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
}

.about-title {
  font-weight: 500;
}

.about-desc {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 50;
  font-size: 14px;
}

@media (max-width: 768px) {

  .about-container {
    /* position: relative; */
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 20%;
    display: flex;
    flex-direction: column;
  }

  .about-title {
    font-size: 16px;
  }

  .about-desc {
    font-size: 12px;
  }

}