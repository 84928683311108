.tech-content {
  z-index: 3;
  top: 18%;
  left: 60%;
  transform: translate(-60%, 0%);
  width: 80%;
  height: 80%;
  position: absolute;
  display: grid;
  grid-template-columns: 10% 30% 5% 30% 20%;
  grid-template-rows: auto;
  row-gap: 0px;
  grid-template-areas: 
    "cdfh cdfh cdfh bph bph"
    "cdf cdf cdf bp bp"
    ". voltah voltah bp bp"
    ". volta volta bp bp"
    ". volta volta bp bp"
    ". volta volta bp bp";
}

.tech-container1 {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 20px;
  padding: 5%;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  /* grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3; */
  grid-area: cdf;
  /* justify-content:  */
  /* height: 30%;
  width: 30%; */
  border: 2px solid black;
  background-color: #fffced;
}


.tech-container2 {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 20px;
  padding: 5%;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  /* grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4; */
  grid-area: volta;
  border: 2px solid black;
  /* justify-content:  */
  /* height: 30%;
  width: 30%; */
  background-color: #fffcede0;
}


.tech-container3 {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 20px;
  padding: 5%;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  /* grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4; */
  grid-area: bp;
  border: 2px solid black;
  /* justify-content:  */
  /* height: 30%;
  width: 30%; */
  background-color: #fffced;
}

.tech-content-container {
  border: 2px solid black;
  justify-self: stretch;
}

.tech-title1 {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: #25d8f7;
  margin-left: 5%;
  align-self: end;
  /* margin-bottom: 10px; */
  grid-area: cdfh;
}

.tech-title2 {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: #25d8f7;
  margin-left: 5%;
  align-self: end;
  /* margin-bottom: 10px; */
  grid-area: voltah;
}

.tech-title3 {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: #25d8f7;
  margin-left: 5%;
  align-self: end;
  /* margin-bottom: 10px; */
  grid-area: bph;
}


.tech-desc {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
}

.unloop-img {
  width: 40%;
  height: auto;
  max-height: 200px;
  /* padding-bottom: 10%; */
  margin-left: 10px;
}

.unloop-container {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.unloop-content {
  display: flex;
  flex-direction: column;
}

.unloop-title {
  font-weight: 1000;
  font-size: 1.5vw;
}

.unloop-desc {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
}

.login-img {
  width: 25%;
  height: auto;
  max-height: 100px;
  border-radius: 5%;
  margin-right: 10px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.login-content {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.login-title {
  font-weight: 1000;
  font-size: 1.5vw;
}

.login-desc {
  margin-top: -5px;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
}

.volta-img {
  width: 25%;
  height: auto;
  max-height: 100px;
  margin-right: 10px;
}

@media (max-width: 768px) {

  .tech-content {
    z-index: 3;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    position: absolute;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    row-gap: 0px;
    grid-template-areas: 
      "cdfh"
      "cdf"
      "voltah"
      "volta"
      "bph"
      "bp";
  }

  .tech-container1 {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 5%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
  }


  .tech-container2 {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 5%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
  }


  .tech-container3 {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 5%;
    justify-content: center;
    align-items: center;
    grid-area: bp;
    border: 1px solid black;
  }

  .tech-desc {
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1vh;
  }

  .tech-title1 {
    margin-left: 0%;
    font-size: 16px;
  }

  .tech-title2 {
    margin-left: 0%;
    font-size: 16px;
  }

  .tech-title3 {
    margin-left: 0%;
    font-size: 16px;
  }

  .unloop-title {
    font-size: 1.5vh;
  }
  
  .unloop-desc {
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1vh;
  }

  .login-title {
    font-size: 1.5vh;
  }
  
  .login-desc {
    font-size: 1.1vh;
  }

}