.timeline {
  left: 50%;
  top: 25%;
  width: 60%;
  height: 100px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 50;
  font-size: 1.1vw;
  z-index: 3;
}

.timeline-content {
  z-index: 3;
  margin-top: 5%;
  padding: 2%;
  top: 25%;
  width: 50%;
  max-width: 800px;
  max-height: 50%;
  left: 22%;
  /* transform: translate(-22%, 0%); */
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fffced;
  border: 3px solid #000000;
}

.timeline-img {
  width: 40%;
  max-height: 30%;
  height: auto;
  margin: 0% 10px;
  border: 3px solid #000000;
  z-index: 3;
}

.timeline-img-hover {
  max-width: 50%;
  max-height: 50%;
  height: auto;
  margin: 0% 5%;
  position: absolute;
  top: 65%;
  left: 45%;
  transform: translate(-50%, -50%);
  border: 5px solid black;
}
  /* top: 50%;
    left: 50%;
    margin-right: -50%;
    
  border: 3px solid #000000;
  z-index: 3;
}
/* 
  .timeline-img:hover {
    width: 100%;
    position: absolute;
    height: auto;
  } */

.fade1-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade1-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade1-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade1-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.fade1-enter-active,
.fade1-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.fade2-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade2-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade2-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade2-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}

.fade2-enter-active,
.fade2-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.timeline-desc {
  display: flex;
  flex-direction: column;
  z-index: 3;
  /* justify-content: space-between; */
}

.timeline-title {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: #ff9901;
  margin-bottom: 10px;
  word-wrap: break-word;
  z-index: 3;
}

.timeline-text {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  z-index: 3;
}


@media (max-width: 768px) {

  .timeline {
    left: 50%;
    top: 20%;
    width: 90%;
    height: 100px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 50;
    font-size: 1.1vw;
    z-index: 3;
  }

  .timeline-content {
    left: 50%;
    top: 48%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #000000;
  }

  .timeline-text {
    font-size: 1.5vh;
  }

  .timeline-img {
    width: 90%;
    max-width: 200px;
    max-height: 50%;
    margin: 0% 0%;
    border: 1px solid black;
  }

  .timeline-img-hover {
    max-width: 80%;
    max-height: 50%;
    height: auto;
    width: 80%;
    /* margin: 0% 5%;
    position: absolute; */
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    /* top: 50%;
      left: 50%;
      margin-right: -50%;
    
    /* border: 3px solid #000000;
    z-index: 3; */
  }
}
