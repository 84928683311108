@import url(https://use.typekit.net/tmf1hpu.css);
html {
  scroll-behavior: smooth;
}

body {
  background-color: #fffced;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, p22-mackinac-pro, 'Garamond', 'Palatino'
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: p22-mackinac-pro, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  margin: 0;
}

h2 {
  font-family: p22-mackinac-pro, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 4vw;
  margin: 0;
}

h3 {
  font-family: p22-mackinac-pro, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  margin: 0;
}

code {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important; 
  -webkit-text-decoration-color: #ff9901 !important; 
          text-decoration-color: #ff9901 !important;
  /* text-decoration-style: dashed !important; */
}

a:hover {
  text-decoration: none !important;
}

hr.dashed {
  border-top: 1px dashed #ff9901;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* br {
  margin: -5px;
} */

/* a:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .6s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
} */ 

/* 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* 
@import url("https://use.typekit.net/tmf1hpu.css");
@import url("https://use.typekit.net/eiu4gmp.css");

h1 {
  font-family: sofia-pro, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    serif;
}

body {
  margin: 0;
  font-family: p22-mackinac-pro, 'Big Caslon', 'Garamond', 'Georgia', 'Palatino'
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 */


.landing-container {
  height:100vh;
  min-height:100vh;
  position: relative;
}

.other-page-container {
  height:100vh;
  min-height:100vh;
  position: relative;
  background-color: rgba(255, 252, 237, 0.9);
  z-index: 2;
}

.intro-container {
  left: 50%;
  top: 65%;
  position: absolute;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.intro-text {
  z-index: 3;
  position: relative;
}

.block-img {
  background-color:  #fffced;
  bottom: 0px;
  left: -50%;
  width: 200%;
  height: 115%;
  z-index: 2;
  position: absolute;
  background: linear-gradient(
    to top,
    #fffced,
    #fffced 80%,
    #fffced 10%,
    rgba(255, 255, 255, 0)
  );
}

.intro-places-img {
  z-index: 0;
  width: 150%;
  height: auto;
  left: -22%;
  top: -66%;
  position: absolute;
  -webkit-animation: rotation 50s infinite linear;
          animation: rotation 50s infinite linear;
}


.intro-clouds-img {
  z-index: 1;
  width: 170%;
  height: auto;
  left: -34%;
  top: -66%;
  position: absolute;
}

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.heading {
  position: absolute;
  margin-left: 5%;
  top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
  z-index: 3;
}

.heading-info {
  display: flex;
  flex-direction: column;
}

.heading-links {
  margin: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
  z-index: 3;
}

.email {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 50;
  font-size: 14px;
}

.heading-link {

}

.art-heading {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: dotted;
          text-decoration-style: dotted;
  -webkit-text-decoration-color: #ff9901;
          text-decoration-color: #ff9901;
  z-index: 3;
}

.tech-heading {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: dotted;
          text-decoration-style: dotted;
  -webkit-text-decoration-color: #25d8f7;
          text-decoration-color: #25d8f7;
  z-index: 3;
}

.about-heading {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: dotted;
          text-decoration-style: dotted;
  -webkit-text-decoration-color: #06cc21;
          text-decoration-color: #06cc21;
  z-index: 3;
}

.links {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 3;

  width: 100%;
}

.link-long {
  width: 60px;
  /* overflow-wrap: break-word; */
}

.link-orange {
  background: linear-gradient(to bottom, var(--mainColor) 0%, var(--mainColor) 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 3px 3px;
  color: #000;
  text-decoration: none;
}

.link-orange:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .6s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
}

.art-img {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 30%;
  height: auto;
}

.coding-img {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 30%;
  height: auto;
}

@media (max-width: 768px) {

  .art-img {
    z-index: 2;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: auto;
    height: 30%;
  }

  .coding-img {
    z-index: 2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: auto;
    height: 30%;
  }

}

@media (max-width: 500px) {
  .block-img {
    background-color:  #fffced;
    bottom: 0px;
    left: -50%;
    width: 200%;
    height: 138%;
    z-index: 2;
    position: absolute;
    background: linear-gradient(
      to top,
      #fffced,
      #fffced 80%,
      #fffced 20%,
      rgba(255, 255, 255, 0)
    );
  }

  .intro-text {
    margin: 0px 60px;
  }

  .intro {
    text-align: center;
  }

  .heading {
    flex-direction: column;
    /* align-items: center; */
  }

  .email {
    text-align: center;
  }

  .heading-info {
    margin: 20px;
    border-bottom: 3px dotted  #06cc21;
  }

}

.timeline {
  left: 50%;
  top: 25%;
  width: 60%;
  height: 100px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 50;
  font-size: 1.1vw;
  z-index: 3;
}

.timeline-content {
  z-index: 3;
  margin-top: 5%;
  padding: 2%;
  top: 25%;
  width: 50%;
  max-width: 800px;
  max-height: 50%;
  left: 22%;
  /* transform: translate(-22%, 0%); */
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fffced;
  border: 3px solid #000000;
}

.timeline-img {
  width: 40%;
  max-height: 30%;
  height: auto;
  margin: 0% 10px;
  border: 3px solid #000000;
  z-index: 3;
}

.timeline-img-hover {
  max-width: 50%;
  max-height: 50%;
  height: auto;
  margin: 0% 5%;
  position: absolute;
  top: 65%;
  left: 45%;
  transform: translate(-50%, -50%);
  border: 5px solid black;
}
  /* top: 50%;
    left: 50%;
    margin-right: -50%;
    
  border: 3px solid #000000;
  z-index: 3;
}
/* 
  .timeline-img:hover {
    width: 100%;
    position: absolute;
    height: auto;
  } */

.fade1-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade1-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade1-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade1-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.fade1-enter-active,
.fade1-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.fade2-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade2-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade2-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade2-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}

.fade2-enter-active,
.fade2-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.timeline-desc {
  display: flex;
  flex-direction: column;
  z-index: 3;
  /* justify-content: space-between; */
}

.timeline-title {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
  -webkit-text-decoration-color: #ff9901;
          text-decoration-color: #ff9901;
  margin-bottom: 10px;
  word-wrap: break-word;
  z-index: 3;
}

.timeline-text {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  z-index: 3;
}


@media (max-width: 768px) {

  .timeline {
    left: 50%;
    top: 20%;
    width: 90%;
    height: 100px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 50;
    font-size: 1.1vw;
    z-index: 3;
  }

  .timeline-content {
    left: 50%;
    top: 48%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #000000;
  }

  .timeline-text {
    font-size: 1.5vh;
  }

  .timeline-img {
    width: 90%;
    max-width: 200px;
    max-height: 50%;
    margin: 0% 0%;
    border: 1px solid black;
  }

  .timeline-img-hover {
    max-width: 80%;
    max-height: 50%;
    height: auto;
    width: 80%;
    /* margin: 0% 5%;
    position: absolute; */
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    /* top: 50%;
      left: 50%;
      margin-right: -50%;
    
    /* border: 3px solid #000000;
    z-index: 3; */
  }
}

.tech-content {
  z-index: 3;
  top: 18%;
  left: 60%;
  transform: translate(-60%, 0%);
  width: 80%;
  height: 80%;
  position: absolute;
  display: grid;
  grid-template-columns: 10% 30% 5% 30% 20%;
  grid-template-rows: auto;
  grid-row-gap: 0px;
  row-gap: 0px;
  grid-template-areas: 
    "cdfh cdfh cdfh bph bph"
    "cdf cdf cdf bp bp"
    ". voltah voltah bp bp"
    ". volta volta bp bp"
    ". volta volta bp bp"
    ". volta volta bp bp";
}

.tech-container1 {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 20px;
  padding: 5%;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  /* grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3; */
  grid-area: cdf;
  /* justify-content:  */
  /* height: 30%;
  width: 30%; */
  border: 2px solid black;
  background-color: #fffced;
}


.tech-container2 {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 20px;
  padding: 5%;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  /* grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4; */
  grid-area: volta;
  border: 2px solid black;
  /* justify-content:  */
  /* height: 30%;
  width: 30%; */
  background-color: #fffcede0;
}


.tech-container3 {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 20px;
  padding: 5%;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  /* grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4; */
  grid-area: bp;
  border: 2px solid black;
  /* justify-content:  */
  /* height: 30%;
  width: 30%; */
  background-color: #fffced;
}

.tech-content-container {
  border: 2px solid black;
  justify-self: stretch;
}

.tech-title1 {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
  -webkit-text-decoration-color: #25d8f7;
          text-decoration-color: #25d8f7;
  margin-left: 5%;
  align-self: end;
  /* margin-bottom: 10px; */
  grid-area: cdfh;
}

.tech-title2 {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
  -webkit-text-decoration-color: #25d8f7;
          text-decoration-color: #25d8f7;
  margin-left: 5%;
  align-self: end;
  /* margin-bottom: 10px; */
  grid-area: voltah;
}

.tech-title3 {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
  -webkit-text-decoration-color: #25d8f7;
          text-decoration-color: #25d8f7;
  margin-left: 5%;
  align-self: end;
  /* margin-bottom: 10px; */
  grid-area: bph;
}


.tech-desc {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
}

.unloop-img {
  width: 40%;
  height: auto;
  max-height: 200px;
  /* padding-bottom: 10%; */
  margin-left: 10px;
}

.unloop-container {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.unloop-content {
  display: flex;
  flex-direction: column;
}

.unloop-title {
  font-weight: 1000;
  font-size: 1.5vw;
}

.unloop-desc {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
}

.login-img {
  width: 25%;
  height: auto;
  max-height: 100px;
  border-radius: 5%;
  margin-right: 10px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.login-content {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.login-title {
  font-weight: 1000;
  font-size: 1.5vw;
}

.login-desc {
  margin-top: -5px;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
}

.volta-img {
  width: 25%;
  height: auto;
  max-height: 100px;
  margin-right: 10px;
}

@media (max-width: 768px) {

  .tech-content {
    z-index: 3;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    position: absolute;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-template-areas: 
      "cdfh"
      "cdf"
      "voltah"
      "volta"
      "bph"
      "bp";
  }

  .tech-container1 {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 5%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
  }


  .tech-container2 {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 5%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
  }


  .tech-container3 {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 5%;
    justify-content: center;
    align-items: center;
    grid-area: bp;
    border: 1px solid black;
  }

  .tech-desc {
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1vh;
  }

  .tech-title1 {
    margin-left: 0%;
    font-size: 16px;
  }

  .tech-title2 {
    margin-left: 0%;
    font-size: 16px;
  }

  .tech-title3 {
    margin-left: 0%;
    font-size: 16px;
  }

  .unloop-title {
    font-size: 1.5vh;
  }
  
  .unloop-desc {
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1vh;
  }

  .login-title {
    font-size: 1.5vh;
  }
  
  .login-desc {
    font-size: 1.1vh;
  }

}
.about-container {
  position: absolute;
  left: 10%;
  top: 20%;
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
}

.about-title {
  font-weight: 500;
}

.about-desc {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 50;
  font-size: 14px;
}

@media (max-width: 768px) {

  .about-container {
    /* position: relative; */
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 20%;
    display: flex;
    flex-direction: column;
  }

  .about-title {
    font-size: 16px;
  }

  .about-desc {
    font-size: 12px;
  }

}
