.landing-container {
  height:100vh;
  min-height:100vh;
  position: relative;
}

.other-page-container {
  height:100vh;
  min-height:100vh;
  position: relative;
  background-color: rgba(255, 252, 237, 0.9);
  z-index: 2;
}

.intro-container {
  left: 50%;
  top: 65%;
  position: absolute;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.intro-text {
  z-index: 3;
  position: relative;
}

.block-img {
  background-color:  #fffced;
  bottom: 0px;
  left: -50%;
  width: 200%;
  height: 115%;
  z-index: 2;
  position: absolute;
  background: linear-gradient(
    to top,
    #fffced,
    #fffced 80%,
    #fffced 10%,
    rgba(255, 255, 255, 0)
  );
}

.intro-places-img {
  z-index: 0;
  width: 150%;
  height: auto;
  left: -22%;
  top: -66%;
  position: absolute;
  animation: rotation 50s infinite linear;
}


.intro-clouds-img {
  z-index: 1;
  width: 170%;
  height: auto;
  left: -34%;
  top: -66%;
  position: absolute;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.heading {
  position: absolute;
  margin-left: 5%;
  top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
  z-index: 3;
}

.heading-info {
  display: flex;
  flex-direction: column;
}

.heading-links {
  margin: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
  z-index: 3;
}

.email {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 50;
  font-size: 14px;
}

.heading-link {

}

.art-heading {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color: #ff9901;
  z-index: 3;
}

.tech-heading {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color: #25d8f7;
  z-index: 3;
}

.about-heading {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color: #06cc21;
  z-index: 3;
}

.links {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 3;

  width: 100%;
}

.link-long {
  width: 60px;
  /* overflow-wrap: break-word; */
}

.link-orange {
  background: linear-gradient(to bottom, var(--mainColor) 0%, var(--mainColor) 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 3px 3px;
  color: #000;
  text-decoration: none;
}

.link-orange:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .6s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
}

.art-img {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 30%;
  height: auto;
}

.coding-img {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 30%;
  height: auto;
}

@media (max-width: 768px) {

  .art-img {
    z-index: 2;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: auto;
    height: 30%;
  }

  .coding-img {
    z-index: 2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: auto;
    height: 30%;
  }

}

@media (max-width: 500px) {
  .block-img {
    background-color:  #fffced;
    bottom: 0px;
    left: -50%;
    width: 200%;
    height: 138%;
    z-index: 2;
    position: absolute;
    background: linear-gradient(
      to top,
      #fffced,
      #fffced 80%,
      #fffced 20%,
      rgba(255, 255, 255, 0)
    );
  }

  .intro-text {
    margin: 0px 60px;
  }

  .intro {
    text-align: center;
  }

  .heading {
    flex-direction: column;
    /* align-items: center; */
  }

  .email {
    text-align: center;
  }

  .heading-info {
    margin: 20px;
    border-bottom: 3px dotted  #06cc21;
  }

}
